#contact-container {
    background-color: darkblue;
    color: white;
    padding-bottom: 7.5em;
}

#contact-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5.5em;
}
#contact-container-header {
    color: white;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
}
#contact-header-bar {
    background-color: white;
    height: 4px;
    width: 70px;
}

.contact-wrapper {
    margin: 0 5%;
}

.contact-description {
    padding-bottom: 2em;
}

#contact-message {
    margin: 0 15%;
    padding-bottom: 2em;
    font-size: 2em;
    line-height: 1.6;
}

#email-button {
    display: block;
    width: 15%;
    padding: 1.5em 1em;
    margin: 1.5em auto 3em auto;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1em;
    color: white;
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 4px;
    transition: background-color 0.5s ease;
}
#email-button:hover {
    background-color: white;
    color: darkblue;
}

.contact-links {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.contact-links > a {
    margin: 0em 0.5em;
    padding: 0em 0.5em;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.5s ease-in-out;
}

.contact-links > a:hover {
    border-bottom: 1px solid white;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    #contact-message {
        font-size: 1.25em;
        margin: 0;
    }
    #email-button {
        width: 50%;
    }
}