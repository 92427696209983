@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.header[role="banner"] {
    top: 0px;
    width: 100%;
    position: fixed;
    z-index: 10;
}

.header-show[role="banner"] {
    top: 0px;
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: white;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}
.page-wrapper {
    width: 90%;
    margin: 0 5%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
}
.burger {
    background-color: white;
}
.header-show .burger {
    background-color: darkblue;
}

.logo {
    width: 25%;
    padding: 0.5em 0em;
    display: -webkit-flex;
    display: flex;
    color: white;
    font-weight: bold;
    color: white;
    font-size: 1.5em;
    text-decoration: none;
}
.header-show .logo {
    color: darkblue;
}
.nav-bar {
    width: 75%;
}

@media (max-width: 768px) {
    .nav-bar {
        background-color: midnightblue;
    }
}
.header-show .nav-bar {
    background-color: white;
}

.nav-bar a {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 0.875em;
    text-decoration: none;
    padding: 1em 2em;
}
.header-show .nav-bar a {
    color: darkblue;
}

.header .nav-bar a:hover {
    border-bottom: 3px solid white;
}
.header-show .nav-bar a:hover {
    border-bottom: 3px solid darkblue;
}
@-webkit-keyframes fade-in {
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blob-load {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.2;
    }

}

@keyframes blob-load {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.2;
    }

}

#home-container {
    background: darkblue url(/static/media/shark.459bc5a9.JPG) 50% 0 no-repeat fixed;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 2, 85, 0.6);
    padding: 12.5em 0;
    color: white;
    position: relative;
}

#title, #intro, #intro-button {
    position: relative;
    z-index: 2;
}

#title {
    font-size: 70px;
    opacity: 0;
    text-transform: uppercase;
    -webkit-animation: fade-in ease-in 3s;
            animation: fade-in ease-in 3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    margin: 0 5%;
    padding: 1em 0 0.5em 0;
}

#intro {
    font-size: 2em;
    opacity: 0;
    -webkit-animation: fade-in ease-in 3s;
            animation: fade-in ease-in 3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
    margin: 0 5%;
}

#intro-button {
    display: block;
    width: 15%;
    padding: 1.5em 1em;
    margin: 5em auto 5em auto;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    color: white;
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 4px;
    transition: background-color 0.5s ease;
    opacity: 0;
    -webkit-animation: fade-in ease-in 3s;
            animation: fade-in ease-in 3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}
#intro-button:hover {
    background-color: white;
    color: darkblue;
}

/* Blob Container */
#blob-container {
    position: absolute;
    -webkit-transform: translateY(-105%);
            transform: translateY(-105%);
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    line-height: 0;

    -webkit-animation: blob-load ease-in 3s;

            animation: blob-load ease-in 3s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

#blob-container svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
}


@media only screen and (max-width: 280px) {
    /* For Galaxy Fold: */
    #title {
        font-size: 50px;
        padding-top: 0;
    }
    #intro {
        font-size: 1.5em;
    }
    #intro-button {
        font-size: 0.75em;
    }
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    #home-container {
        padding: 5.5em 0;
    }
    #intro-button {
        width: 50%;
    }
}
#about-container {
    padding-top: 5.5em;
    position: relative;
}

#about-header-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 5.5em;
}
#about-header {
    color: midnightblue;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-bottom: 0.5em;
}
#about-header-bar {
    background-color: midnightblue;
    height: 4px;
    width: 70px;
}

.about-wrapper {
    margin: 0 5%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-bottom: 5.5em;
}

.my-description {
    width: 100%;
    border-right: 1px solid rgb(128, 128, 128, 0.1);
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-template-rows: 1fr;
    -webkit-align-items: center;
            align-items: center;
}
#description-grid-item {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    -webkit-align-items: end;
            align-items: end;
}

#profile-picture{
    max-width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.my-description-text {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.my-values {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.values-item {
    -webkit-flex: 1 1 50%;
            flex: 1 1 50%;
    -webkit-align-self: center;
            align-self: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.values-item span, h3, p {
    margin: 8px 16px;
}

.material-icons{
    color: darkblue;
    font-size: 300%;
}

/* Wave transitions */
.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.wave .shape-fill-blue {
    fill: darkblue;
}

.wave-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    z-index: -1;
}

.wave-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 120px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.wave-bottom .shape-fill-grey {
    fill: lightgray;
}


@media only screen and (max-width: 1024px) {
    /* For tablets: */
    .about-wrapper {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .my-description {
        grid-template-columns: 1fr;
        margin-bottom: 2em;
        border-right: 1px solid rgb(128, 128, 128, 0.0);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .values-item {
        -webkit-flex: 1 1 100%;
                flex: 1 1 100%;
        padding-top: 2em;
    }
}

@media only screen and (max-width: 480px) {
    /* For mobile phones: */
    .my-description-text {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

#project-container {
    background-color: lightgray;
}

#project-header-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 5.5em 0em;
}
#project-header {
    color: midnightblue;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-bottom: 0.5em;
}
#project-header-bar {
    background-color: midnightblue;
    height: 4px;
    width: 70px;
}

.project-list {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 5%;
    padding-bottom: 7.5em;
}

.project-item {
    height: 33vh;
    -webkit-flex: 0 0 32%;
            flex: 0 0 32%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: box-shadow 0.5s ease;
    margin: 0.2em;
    border-color: darkblue;
    border-style: solid;
    border-width: thin;
}
@media only screen and (max-width: 1024px) {
    /* For mobile tablets: */
    .project-item {
        -webkit-flex: 1 0 100%;
                flex: 1 0 100%;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile tablets: */
    .project-item {
        -webkit-flex: 1 0 100%;
                flex: 1 0 100%;
        height: 66vh;
    }
}

/* Project containers/thumbnails */
#euros {
    background-image: url(/static/media/euros_predictor_dashboard.d16d1868.png);
    -webkit-order: 1;
            order: 1;
}
#golden-shoe {
    background-image: url(/static/media/golden_shoe.fe2e867d.png);
    -webkit-order: 2;
            order: 2
}
#pony-maze {
    background-image: url(/static/media/pony_maze.47b23041.png);
    -webkit-order: 3;
            order: 3;
}
#leaderboard {
    background-image: url(/static/media/leaderboard_2.eb6b8774.png);
    -webkit-order: 4;
            order: 4;
}
#shares {
    background-image: url(/static/media/share_portfolio_tracker.1846a40e.png);
    -webkit-order: 5;
            order: 5;
}
#gym {
    background-image: url(/static/media/gym_web_app.fd758c2a.png);
    -webkit-order: 6;
            order: 6;
}

.project-name {
    background-color: darkblue;
    color: white;
    width: 75%;
    height: 20%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
    margin-bottom: 0;
    transition: margin-bottom 0.5s ease;
}

.learn-more-button {
    opacity: 0;
    transition: opacity .35s ease;
    padding: 0.5em 1.0em;
    margin-bottom: 1em;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.9em;
    color: darkblue;
    border-style: solid;
    border-width: 2px;
    border-color: darkblue;
}

.project-item:hover .learn-more-button {
    opacity: 1;
}
.project-item:hover .project-name {
    margin-bottom: 1em;
    margin-top: 0;
}
.project-item:hover {
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.8);
}

.project-tech{
    opacity: 0;
    transition: opacity .35s ease;
    margin: 0;
    transition: margin-bottom .5s ease;
    color: midnightblue;
}
.project-item:hover .project-tech {
    opacity: 1;
    margin-bottom: 4em;
}

.learn-more-button:hover {
    background-color: darkblue;
    color: white;
}

/* Section Transitions */
.spacer {
    aspect-ratio: 960/160;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.waves-grey-to-blue {
    background-image: url(/static/media/layerd_waves_grey-blue.71a4da12.svg);
}



@media only screen and (max-width: 280px) {
    /* For Galaxy Fold: */
    .project-name {
        width: 90%;
    }
}
#contact-container {
    background-color: darkblue;
    color: white;
    padding-bottom: 7.5em;
}

#contact-header-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 5.5em;
}
#contact-container-header {
    color: white;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
}
#contact-header-bar {
    background-color: white;
    height: 4px;
    width: 70px;
}

.contact-wrapper {
    margin: 0 5%;
}

.contact-description {
    padding-bottom: 2em;
}

#contact-message {
    margin: 0 15%;
    padding-bottom: 2em;
    font-size: 2em;
    line-height: 1.6;
}

#email-button {
    display: block;
    width: 15%;
    padding: 1.5em 1em;
    margin: 1.5em auto 3em auto;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1em;
    color: white;
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 4px;
    transition: background-color 0.5s ease;
}
#email-button:hover {
    background-color: white;
    color: darkblue;
}

.contact-links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.contact-links > a {
    margin: 0em 0.5em;
    padding: 0em 0.5em;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.5s ease-in-out;
}

.contact-links > a:hover {
    border-bottom: 1px solid white;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    #contact-message {
        font-size: 1.25em;
        margin: 0;
    }
    #email-button {
        width: 50%;
    }
}
#footer {
    background-color: midnightblue;
    color: white;
    margin: 0;
    padding: 3em 0;
}

#footer > p {
    margin: 0;
    font-size: 0.75em;
}
.project-info-container{
    background-color: midnightblue;
    color: white;
    padding-top: 5.5em;
    margin: 0%;
}

.project-title-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 2.5em;
}
.project-title {
    color: white;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
}
.project-title-bar {
    background-color: white;
    height: 4px;
    width: 70px;
    margin-bottom: 2.5em;
}

.github-link {
    color: white;
}

.tech-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    list-style: none;
    padding-left: 0;
}
.tech-item {
    -webkit-flex: 0 1 20%;
            flex: 0 1 20%;
    -webkit-align-self: center;
            align-self: center;
    padding: 0.5em 0em;
    margin: 0.5em;
    background-color: white;
    color: midnightblue;
    border-radius: 2px;
    font-weight: bold;
}

.project-image-wrapper {
    padding: 2.5em 0em;
    margin: 0 5%;
}

.project-description {
    font-size: 2em;
    padding-bottom: 1em;
}

.project-link {
    color: white;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .project-description {
        font-size: 1.25em;
        margin: 0;
    }
}
@media only screen and (max-width: 480px) {
    /* For mobile phones: */
    .tech-item {
        -webkit-flex: 0 1 25%;
                flex: 0 1 25%;
        font-size: 0.65em;
    }
}
@media only screen and (max-width: 320px) {
    /* For mobile phones: */
    .tech-item {
        -webkit-flex: 0 1 40%;
                flex: 0 1 40%;
    }
}

.project-image {
    max-width: 100%;
}

.project-brief {
    margin: 0 5%;
    text-align: left;
    font-size: large;
    padding-bottom: 5.5em;
    border-bottom: 1px solid rgb(128, 128, 128, 1);
}

.brief {
    border-bottom: 1px solid rgb(128, 128, 128, 1);
}
