@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

#about-container {
    padding-top: 5.5em;
    position: relative;
}

#about-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5.5em;
}
#about-header {
    color: midnightblue;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-bottom: 0.5em;
}
#about-header-bar {
    background-color: midnightblue;
    height: 4px;
    width: 70px;
}

.about-wrapper {
    margin: 0 5%;
    display: flex;
    justify-content: center;
    padding-bottom: 5.5em;
}

.my-description {
    width: 100%;
    border-right: 1px solid rgb(128, 128, 128, 0.1);
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}
#description-grid-item {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: end;
}

#profile-picture{
    max-width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.my-description-text {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.my-values {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.values-item {
    flex: 1 1 50%;
    align-self: center;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

.values-item span, h3, p {
    margin: 8px 16px;
}

.material-icons{
    color: darkblue;
    font-size: 300%;
}

/* Wave transitions */
.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.wave .shape-fill-blue {
    fill: darkblue;
}

.wave-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: -1;
}

.wave-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 120px;
    transform: rotateY(180deg);
}

.wave-bottom .shape-fill-grey {
    fill: lightgray;
}


@media only screen and (max-width: 1024px) {
    /* For tablets: */
    .about-wrapper {
        flex-wrap: wrap;
    }
    .my-description {
        grid-template-columns: 1fr;
        margin-bottom: 2em;
        border-right: 1px solid rgb(128, 128, 128, 0.0);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .values-item {
        flex: 1 1 100%;
        padding-top: 2em;
    }
}

@media only screen and (max-width: 480px) {
    /* For mobile phones: */
    .my-description-text {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
