#project-container {
    background-color: lightgray;
}

#project-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5.5em 0em;
}
#project-header {
    color: midnightblue;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-bottom: 0.5em;
}
#project-header-bar {
    background-color: midnightblue;
    height: 4px;
    width: 70px;
}

.project-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 5%;
    padding-bottom: 7.5em;
}

.project-item {
    height: 33vh;
    flex: 0 0 32%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.5s ease;
    margin: 0.2em;
    border-color: darkblue;
    border-style: solid;
    border-width: thin;
}
@media only screen and (max-width: 1024px) {
    /* For mobile tablets: */
    .project-item {
        flex: 1 0 100%;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile tablets: */
    .project-item {
        flex: 1 0 100%;
        height: 66vh;
    }
}

/* Project containers/thumbnails */
#euros {
    background-image: url('../images//project_screenshots/euros_predictor_dashboard.png');
    order: 1;
}
#golden-shoe {
    background-image: url('../images//project_screenshots/golden_shoe.png');
    order: 2
}
#pony-maze {
    background-image: url('../images//project_screenshots/pony_maze.png');
    order: 3;
}
#leaderboard {
    background-image: url('../images//project_screenshots/leaderboard_2.png');
    order: 4;
}
#shares {
    background-image: url('../images//project_screenshots/share_portfolio_tracker.png');
    order: 5;
}
#gym {
    background-image: url('../images//project_screenshots/gym_web_app.png');
    order: 6;
}

.project-name {
    background-color: darkblue;
    color: white;
    width: 75%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-bottom: 0;
    transition: margin-bottom 0.5s ease;
}

.learn-more-button {
    opacity: 0;
    transition: opacity .35s ease;
    padding: 0.5em 1.0em;
    margin-bottom: 1em;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.9em;
    color: darkblue;
    border-style: solid;
    border-width: 2px;
    border-color: darkblue;
}

.project-item:hover .learn-more-button {
    opacity: 1;
}
.project-item:hover .project-name {
    margin-bottom: 1em;
    margin-top: 0;
}
.project-item:hover {
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.8);
}

.project-tech{
    opacity: 0;
    transition: opacity .35s ease;
    margin: 0;
    transition: margin-bottom .5s ease;
    color: midnightblue;
}
.project-item:hover .project-tech {
    opacity: 1;
    margin-bottom: 4em;
}

.learn-more-button:hover {
    background-color: darkblue;
    color: white;
}

/* Section Transitions */
.spacer {
    aspect-ratio: 960/160;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.waves-grey-to-blue {
    background-image: url('../images/transitions/layerd_waves_grey-blue.svg');
}



@media only screen and (max-width: 280px) {
    /* For Galaxy Fold: */
    .project-name {
        width: 90%;
    }
}