#footer {
    background-color: midnightblue;
    color: white;
    margin: 0;
    padding: 3em 0;
}

#footer > p {
    margin: 0;
    font-size: 0.75em;
}