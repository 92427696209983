.project-info-container{
    background-color: midnightblue;
    color: white;
    padding-top: 5.5em;
    margin: 0%;
}

.project-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2.5em;
}
.project-title {
    color: white;
    text-transform: uppercase;
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
}
.project-title-bar {
    background-color: white;
    height: 4px;
    width: 70px;
    margin-bottom: 2.5em;
}

.github-link {
    color: white;
}

.tech-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding-left: 0;
}
.tech-item {
    flex: 0 1 20%;
    align-self: center;
    padding: 0.5em 0em;
    margin: 0.5em;
    background-color: white;
    color: midnightblue;
    border-radius: 2px;
    font-weight: bold;
}

.project-image-wrapper {
    padding: 2.5em 0em;
    margin: 0 5%;
}

.project-description {
    font-size: 2em;
    padding-bottom: 1em;
}

.project-link {
    color: white;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .project-description {
        font-size: 1.25em;
        margin: 0;
    }
}
@media only screen and (max-width: 480px) {
    /* For mobile phones: */
    .tech-item {
        flex: 0 1 25%;
        font-size: 0.65em;
    }
}
@media only screen and (max-width: 320px) {
    /* For mobile phones: */
    .tech-item {
        flex: 0 1 40%;
    }
}

.project-image {
    max-width: 100%;
}

.project-brief {
    margin: 0 5%;
    text-align: left;
    font-size: large;
    padding-bottom: 5.5em;
    border-bottom: 1px solid rgb(128, 128, 128, 1);
}

.brief {
    border-bottom: 1px solid rgb(128, 128, 128, 1);
}