.header[role="banner"] {
    top: 0px;
    width: 100%;
    position: fixed;
    z-index: 10;
}

.header-show[role="banner"] {
    top: 0px;
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: white;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}
.page-wrapper {
    width: 90%;
    margin: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.burger {
    background-color: white;
}
.header-show .burger {
    background-color: darkblue;
}

.logo {
    width: 25%;
    padding: 0.5em 0em;
    display: flex;
    color: white;
    font-weight: bold;
    color: white;
    font-size: 1.5em;
    text-decoration: none;
}
.header-show .logo {
    color: darkblue;
}
.nav-bar {
    width: 75%;
}

@media (max-width: 768px) {
    .nav-bar {
        background-color: midnightblue;
    }
}
.header-show .nav-bar {
    background-color: white;
}

.nav-bar a {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 0.875em;
    text-decoration: none;
    padding: 1em 2em;
}
.header-show .nav-bar a {
    color: darkblue;
}

.header .nav-bar a:hover {
    border-bottom: 3px solid white;
}
.header-show .nav-bar a:hover {
    border-bottom: 3px solid darkblue;
}