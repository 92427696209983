@keyframes fade-in {
    100% {
        opacity: 1;
    }
}

@keyframes blob-load {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.2;
    }

}

#home-container {
    background: darkblue url('../images/shark.JPG') 50% 0 no-repeat fixed;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 2, 85, 0.6);
    padding: 12.5em 0;
    color: white;
    position: relative;
}

#title, #intro, #intro-button {
    position: relative;
    z-index: 2;
}

#title {
    font-size: 70px;
    opacity: 0;
    text-transform: uppercase;
    animation: fade-in ease-in 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    margin: 0 5%;
    padding: 1em 0 0.5em 0;
}

#intro {
    font-size: 2em;
    opacity: 0;
    animation: fade-in ease-in 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    margin: 0 5%;
}

#intro-button {
    display: block;
    width: 15%;
    padding: 1.5em 1em;
    margin: 5em auto 5em auto;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    color: white;
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 4px;
    transition: background-color 0.5s ease;
    opacity: 0;
    animation: fade-in ease-in 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}
#intro-button:hover {
    background-color: white;
    color: darkblue;
}

/* Blob Container */
#blob-container {
    position: absolute;
    transform: translateY(-105%);
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    line-height: 0;

    animation: blob-load ease-in 3s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

#blob-container svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
}


@media only screen and (max-width: 280px) {
    /* For Galaxy Fold: */
    #title {
        font-size: 50px;
        padding-top: 0;
    }
    #intro {
        font-size: 1.5em;
    }
    #intro-button {
        font-size: 0.75em;
    }
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    #home-container {
        padding: 5.5em 0;
    }
    #intro-button {
        width: 50%;
    }
}